@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);

.ticket-report-table{
  border-left:0;
  font-size: 11px;
  padding: 0px;
  table-layout: fixed;
}

.ticket-report-table tbody tr { border: 1px black solid; }

.ticket-report-table tbody tr .key{
  background-color: #bdbdbd;
  color: #333333;
  font-weight: bold;
}

.ticket-report-table tbody tr .long-text{
  text-align: justify;
  word-wrap: break-word;
  font-size: 10px;
  padding: 10px;
  vertical-align: unset;
}

.ticket-report-table tbody tr .value{ background-color: #f9f9f9; }

body[class="A4 portrait"] .sheet {
  margin-left: auto;
  margin-right: auto;
}

body[class="A4 landscape"] .sheet {
  margin-left: auto;
  margin-right: auto;
}

.vertical {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.vertical .header {
  padding: 1cm;
}

.vertical .header-content {
  display: flex;
  flex-direction: row;
}

.vertical .header-content > .title {
  flex: 1 1;
  background-color: #ce0814;
  height: 20;
  padding:4px 0px 4px 10px;
  color: white;
  font-weight: bold;
  font-size: 14pt;
}

.vertical .header-content > .logo {
  align-self: flex-end;
  max-height: 120px;
  max-width: 200px;
  height: 28px;
}

.vertical .content {
  flex-grow: 1;
  background-color: white;
  padding: 0 1cm;
  overflow: hidden;
}

.vertical .footer {
  padding: 1cm;
}

.vertical .footer-content {
  font-size: 10pt;
  color: #999;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical .footer-content > .title {
  text-align: center;
}
.Sheet_sheet__26y-O {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.Sheet_sheet__26y-O:not(.Sheet_cover__1YtcF):not(.Sheet_h__35trB){
    counter-increment: headings;
}

body[class="A4 portrait"] .Sheet_sheet__26y-O {
    margin-left: auto;
    margin-right: auto;
}
  
body[class="A4 landscape"] .Sheet_sheet__26y-O {
    margin-left: auto;
    margin-right: auto;
}
.Header_header__sSQKS {
    padding: 7px 20px 7px 0;
    border-bottom: 1.2px solid red!important;
}
  
.Header_header_content__1xvjT {
    display: flex;
    flex-direction: row;
    margin: 10px 0px 10px 0px;
}
  
.Header_header_title__2F97v {
    flex: 1 1;
    height: 30;
    padding: 4px 0px 4px 10px;
    color: #002d53;
    font-weight: bold;
    font-size: 17pt;
    margin-top: 0px;
    margin-left: 15px;
}

.Header_header_title__2F97v:not(.Header_not_heading__2CF-3):before {
    content: counter(headings) ". ";
}
  
.Header_company_logo__73nbV {
    align-self: flex-end;
    max-height: 120px;
    max-width: 200px;
    height: 30;
    padding: 4px 0px 4px 10px;
    margin-left: 15px;
}
.Content_content__1Cgr7 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    padding: 20px;
    overflow: hidden;
}
.Footer_footer__2-pgY{
}

.Footer_footer_content__19YKq {
    font-size: 10pt;
    color: #999;
    display: flex;
    flex-direction: row;
    padding: 30px 20px 10px 20px;
    align-items: center;
}
  
.Footer_page_count__YrDQU {
    padding-right: 5%;
    flex: 1 1;
    text-align: right;
}
  
.Footer_page_count__YrDQU:before {
    counter-increment: page_counter;
    content: "#" counter(page_counter);
}
.InformationBox_information_box_title__2AT1R{
    background-color: red;
    color: white;
    font-size: 12px;
    font-weight: 800;
    padding: 10px;
}
  
.InformationBox_information_box_content__1En1v{
    padding: 10px;
    border: 1px solid;
    margin-top: 10px;
    line-height: 1.7;
}

.PageIndex_index_list__1WU2k {
    list-style-type: none;
    counter-reset: li;
    line-height: 50px;
    font-size: 20px;
    font-weight: 600;
    color: #002d53;
}
  
.PageIndex_list_item__DTV9Z:before {
    counter-increment: li;
    content: counter(li, decimal-leading-zero) ".";
    margin-right: 0.25em;
}
  
.Table_table__1t4RQ {
    border-bottom: 0;
    border-collapse: collapse;
    border-left: 1px black solid;
    margin-top: 30px;
    table-layout:fixed;
}

.Table_table__1t4RQ > thead > tr > td {
    background-color: red;
    color: white;
    font-size: 9.5pt;
    text-align: center;
    padding: 10px;
    border: 1px black solid;
}

.Table_table__1t4RQ > tbody > tr > td {
    padding: 5px;
    border-bottom: 1px black solid;
    border-right: 1px black solid;
    text-align: center;
    font-size: 7pt;
}

.Table_red_table__236Hi > thead > tr > td {
    background-color: red;
    color: white;
    font-size: 9.5pt;
    text-align: center;
    padding: 10px 0px;
    border: 1px black solid;
    word-break: keep-all;
}

.Table_small_text__2MEBU > thead > tr > td{
    font-size: 7pt;
    height: 40px;
}

.Table_budgeted_table__2rF5Q {
    margin-top: 10px;
    table-layout: fixed;
}

.Table_budgeted_table__2rF5Q > thead > tr > td {
    padding: 2px;
}
  
.Table_budgeted_table__2rF5Q > tbody > tr > td {
    font-size: 7pt;
}

.Table_ticket_report_table__3lQVu{
    border-left:0;
    margin-top: 30px;
    font-size: 11px;
    padding: 0px;
    table-layout: fixed;
}

.Table_ticket_report_table__3lQVu tbody tr { border: 1px black solid; }
  
.Table_ticket_table_key__I8If6{
    background-color: #bdbdbd;
    color: #333333;
    font-weight: bold;
}
  
.Table_ticket_table_long_text__23X4F{
    text-align: justify;
    word-wrap: break-word;
    font-size: 10px;
    padding: 10px;
    vertical-align: unset;
}
  
.Table_ticket_table_value__1ZbH4{ background-color: #f9f9f9; }

.Table_zebra_row__7-5F9:nth-child(even) {
    background-color: #e0e0e0;
}
.Sheet_sheet__kmYSP {
  display: flex;
  flex-direction: column;
}

.Sheet_sheet__kmYSP:not(.Sheet_cover__1UpN8):not(.Sheet_h__2WMJn) {
  counter-increment: headings;
}

body[class="A4 portrait"] .Sheet_sheet__kmYSP {
  margin-left: auto;
  margin-right: auto;
}

body[class="A4 landscape"] .Sheet_sheet__kmYSP {
  margin-left: auto;
  margin-right: auto;
}
.Header_header__3eBmC {
  padding: 7px 20px 7px 0;
  border-bottom: 1.2px solid #e00000 !important;
}

.Header_header_content__3KmZl {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 10px 0px;
}

.Header_header_title__3IVKc {
  flex: 1 1;
  height: 30;
  padding: 4px 0px 4px 10px;
  color: #002d53;
  font-weight: bold;
  font-size: 17pt;
  margin-top: 0px;
  margin-left: 15px;
}

.Header_header_title__3IVKc:not(.Header_not_heading__TmcK4):before {
  content: counter(headings) ". ";
}

.Header_company_logo__xVLdL {
  align-self: flex-end;
  max-height: 120px;
  max-width: 200px;
  height: 30;
  padding: 4px 0px 4px 10px;
  margin-left: 15px;
}
.SubHeader_header__2W3xJ {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.SubHeader_small__LNYfG {
  font-size: 15pt !important;
}

.SubHeader_header_title__HPD4x {
  flex: 1 1;
  min-height: 30px;
  padding: 0 0 0 5px;
  color: #002d53;
  font-weight: bold;
  font-size: 17pt;
}

.SubHeader_header_title__HPD4x:not(.SubHeader_not_heading__guDGO):before {
  content: counter(headings) ".";
}
.Content_content__3uLht {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding: 20px;
}

.Content_contentFixed__1Yc0h {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding: 20px;
  overflow: hidden;
}
.Footer_footer_content__3Iv3x {
  font-size: 10pt;
  color: #999;
  display: flex;
  flex-direction: row;
  padding: 30px 20px 10px 20px;
  align-items: center;
}

.Footer_fixed_footer__1n_MX {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Footer_page_count__25rz8 {
  padding-right: 5%;
  flex: 1 1;
  text-align: right;
}

.Footer_page_count__25rz8:before {
  counter-increment: page_counter;
  content: "#" counter(page_counter);
}
.InformationBox_information_box_title__1MEPP {
  background-color: #e00000;
  color: white;
  font-size: 12px;
  font-weight: 800;
  padding: 10px;
  border: 1px solid #000000;
}

.InformationBox_information_box_content__JmNPR {
  padding: 10px;
  border: 1px solid #000000;
  border-top: none;
  line-height: 1.7;
}
.TaskItem_componentContainer__2_4tq {
  margin: 15 0;
}

.TaskItem_headerTitle__n7zb- {
  padding: 4px 0px 4px 10px;
  color: #002d53;
  font-weight: bold;
  font-size: 15pt;
  margin-top: 0;
  margin-left: 8;
}

.TaskItem_headerSubTitle__3Gyky {
  padding: 4px 0px 4px 10px;
  color: #002d53;
  font-weight: bold;
  font-size: '13pt';
  margin-top: 12;
  margin-bottom: 8;
  margin-left: 20;
}
.ImageContentViewer_title__2w_4A {
  background-color: #e00000;
  color: white;
  font-size: 12px;
  font-weight: 800;
  padding: 10px;
  border: 1px solid #000000;
}

.ImageContentViewer_gallery__-N3Uh {
  max-height: 840px;
  overflow: hidden;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  border: 1px solid #000000;
  border-top: none;
}

.ImageContentViewer_gallery_item__2BqEs {
  margin: 0;
}

.ImageContentViewer_gallery_img__2Iq2J {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.PageIndex_index_list__20LHu {
  list-style-type: none;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #002d53;
}

.PageIndex_list_item__37_8E:before {
  margin-right: 0.25em;
}
.Table_table__3Q8KK {
  border-bottom: 0;
  border-collapse: collapse;
  border-left: 1px black solid;
  margin-top: 30px;
  table-layout: fixed;
}

.Table_table__3Q8KK>thead>tr>td {
  background-color: #e00000;
  color: white;
  font-size: 9.5pt;
  text-align: center;
  padding: 10px;
  border: 1px black solid;
}

.Table_table__3Q8KK>tbody>tr>td {
  padding: 5px;
  border-bottom: 1px black solid;
  border-right: 1px black solid;
  text-align: center;
  font-size: 7pt;
}

.Table_red_table__c7ccI>thead>tr>td {
  background-color: #e00000;
  color: white;
  font-size: 9.5pt;
  text-align: center;
  padding: 10px 0px;
  border: 1px black solid;
  word-break: keep-all;
}

.Table_small_text__2BZdZ>thead>tr>td {
  font-size: 7pt;
  height: 40px;
}

.Table_budgeted_table__12J9_ {
  margin-top: 10px;
  table-layout: fixed;
}

.Table_budgeted_table__12J9_>thead>tr>td {
  padding: 2px;
}

.Table_budgeted_table__12J9_>tbody>tr>td {
  font-size: 7pt;
}

.Table_ticket_report_table__2S23G {
  border-left: 0;
  margin-top: 30px;
  font-size: 11px;
  padding: 0px;
  table-layout: fixed;
}

.Table_ticket_report_table__2S23G tbody tr {
  border: 1px black solid;
}

.Table_ticket_table_key__19Xay {
  background-color: #bdbdbd;
  color: #333333;
  font-weight: bold;
}

.Table_ticket_table_long_text__2t4NO {
  text-align: justify;
  word-wrap: break-word;
  font-size: 10px;
  padding: 10px;
  vertical-align: unset;
}

.Table_ticket_table_value__2Lzwx {
  background-color: #f9f9f9;
}

.Table_zebra_row__2X55L:nth-child(even) {
  background-color: #e0e0e0;
}

.Table_multiRow__2P53Y {
  padding: 0 !important;
}

.Table_multiRowItem__1gqbj:last-child {
  border-bottom: 0;
}

.Table_multiRowItem__1gqbj {
  padding: 5;
  border-bottom: 1px solid black;
}
.sheet_sheet__3vMao {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

body[class="A4 portrait"] .sheet_sheet__3vMao {
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #fffaf8;
}
.header_header_row_container__2R0Hm {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header_station_title__1FpRT {
  font-size: 18px;
  font-weight: 600;
  color: rgba(3, 0, 118, 1);
}

.header_report_date__3EcWv {
  font-size: 12px;
  font-weight: 500;
  color: rgba(3, 0, 118, 1);
}

.header_header_logo__2ak-N {
  align-self: flex-end;
  max-height: 120px;
  max-width: 200px;
  height: 30;
}

.header_horizontal_divider__102Ar {
  margin: 1rem 0;
  border-bottom: 1.5px solid rgba(255, 93, 0, 0.5) !important;
}
.portal_data_container__r9NN1 {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  border: 1.5px solid rgba(3, 0, 118, 1);
  border-radius: 15px;
  padding: 1rem 0 0 0;
  background-color: #FFFFFF;
}

.portal_data_container_title__3P9WY {
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(255, 137, 70, 1);
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.portal_data_table_container__2jOFU {
  width: 95%;
  margin: 1rem auto;
}

.portal_data_pd_table__1Lyl_ {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border: unset !important;
}

.portal_data_table_header__2ZoR9>th {
  background-color: transparent;
  padding: 0;
}

.portal_data_table_header_cell__3bjOA {
  position: relative;
  background-color: #03006B26;
  padding: 5px 0px 8px 0px;
  font-weight: 600;
  font-size: 9px;
  color: #030076;
  margin-bottom: 5px;
  min-width: 80px;
}

.portal_data_table_header__2ZoR9>th:nth-of-type(2) .portal_data_table_header_cell__3bjOA {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.portal_data_table_header__2ZoR9>th:nth-of-type(5) .portal_data_table_header_cell__3bjOA {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.portal_data_table_header_cell__3bjOA::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  content: '\0025BC';
}

.portal_data_table_body__r0-iJ>td {
  padding: 7px 0px;
  border: unset !important;
}

.portal_data_table_body__r0-iJ>td:nth-of-type(1) {
  width: 120px;
}

.portal_data_cell__3KnEl {
  margin: 0 5px;
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
}

.portal_data_table_body__r0-iJ>td:nth-of-type(2) .portal_data_cell__3KnEl {
  margin-left: 0;
}

.portal_data_table_body__r0-iJ>td:nth-of-type(5) .portal_data_cell__3KnEl {
  margin-right: 0;
}

.portal_data_pd_table_row__2wDzQ {
  position: relative;
}

.portal_data_row_title__3lYwH {
  max-width: 100px;
  position: relative;
  text-align: left;
  font-size: 9px;
  font-weight: 600;
  padding: 10px 5px;
  border-radius: 5px;
}

.portal_data_title_border__nvjCo {
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 10px;
  width: 60%;
  height: calc(100% + 8px);
}

.portal_data_row_title__3lYwH::after {
  content: '\25B6';
  position: absolute;
  transform: translate(50%, -50%);
  right: 0;
  top: 50%;
}

.portal_data_green__2irB7 {
  background-color: rgba(220, 236, 216, 1);
}

.portal_data_blue__1iXfo {
  background-color: rgba(204, 233, 255, 1);
}

.portal_data_orange__PmFtX {
  background-color: #FCE7D6;
}

.portal_data_purple__2ssoM {
  background-color: rgba(243, 227, 255, 1);
}

.portal_data_title_border__nvjCo.portal_data_green__2irB7 {
  background-color: #519E3E;
}

.portal_data_title_border__nvjCo.portal_data_blue__1iXfo {
  background-color: #3B8DC8;
}

.portal_data_title_border__nvjCo.portal_data_orange__PmFtX {
  background-color: #EF8534;
}

.portal_data_title_border__nvjCo.portal_data_purple__2ssoM {
  background-color: #8E3BC8;
}

.portal_data_green__2irB7::after {
  color: #519E3E;
}

.portal_data_blue__1iXfo::after {
  color: #3B8DC8;
}

.portal_data_orange__PmFtX::after {
  color: #EF8534;
}

.portal_data_purple__2ssoM::after {
  color: #8E3BC8;
}
.portal_vs_amr_container__lBScL {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  border: 1.5px solid rgba(3, 0, 118, 1);
  border-radius: 15px;
  padding: 1rem 0 0 0;
  background-color: #FFFFFF;
}

.portal_vs_amr_container_title__1MHzV {
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(74, 129, 209, 1);
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.portal_vs_amr_table_container__3dgGV {
  width: 95%;
  margin: 1rem auto;
}

.portal_vs_amr_pva_table__MWWvr {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border: unset !important;
}

.portal_vs_amr_table_header__1uuth>th {
  background-color: transparent;
  padding: 0;
}

.portal_vs_amr_table_header_cell__1I6uk {
  position: relative;
  background-color: #03006B26;
  padding: 5px 0px 8px 0px;
  font-weight: 600;
  font-size: 9px;
  color: #030076;
  margin-bottom: 5px;
  min-width: 80px;
}

.portal_vs_amr_table_header__1uuth>th:nth-of-type(2) .portal_vs_amr_table_header_cell__1I6uk {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.portal_vs_amr_table_header__1uuth>th:nth-of-type(5) .portal_vs_amr_table_header_cell__1I6uk {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.portal_vs_amr_table_header_cell__1I6uk::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  content: '\0025BC';
}

.portal_vs_amr_table_body__3bPsZ>td {
  padding: 7px 0px;
  border: unset !important;
}

.portal_vs_amr_table_body__3bPsZ>td:nth-of-type(1) {
  width: 120px;
}

.portal_vs_amr_cell__1dmyo {
  margin: 0 5px;
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
}

.portal_vs_amr_table_body__3bPsZ>td:nth-of-type(2) .portal_vs_amr_cell__1dmyo {
  margin-left: 0;
}

.portal_vs_amr_table_body__3bPsZ>td:nth-of-type(5) .portal_vs_amr_cell__1dmyo {
  margin-right: 0;
}

.portal_vs_amr_pva_table_row__2nq2U {
  position: relative;
}

.portal_vs_amr_row_title__1Yi4J {
  max-width: 100px;
  position: relative;
  text-align: left;
  font-size: 9px;
  font-weight: 600;
  padding: 10px 5px;
  border-radius: 5px;
}

.portal_vs_amr_title_border__CyyFO {
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 10px;
  width: 60%;
  height: calc(100% + 8px);
}

.portal_vs_amr_row_title__1Yi4J::after {
  content: '\25B6';
  position: absolute;
  transform: translate(50%, -50%);
  right: 0;
  top: 50%;
}

.portal_vs_amr_green__18GxS {
  background-color: rgba(220, 236, 216, 1);
}

.portal_vs_amr_pink__3xgC7 {
  background-color: rgba(255, 230, 247, 1);
}

.portal_vs_amr_title_border__CyyFO.portal_vs_amr_green__18GxS {
  background-color: #519E3E;
}

.portal_vs_amr_title_border__CyyFO.portal_vs_amr_pink__3xgC7 {
  background-color: #E152B3;
}

.portal_vs_amr_green__18GxS::after {
  color: #519E3E;
}

.portal_vs_amr_pink__3xgC7::after {
  color: #E152B3;
}
.production_graph_container__2CTLo {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  border: 1.5px solid rgba(3, 0, 118, 1);
  border-radius: 15px;
  padding: 1rem 0 0 0;
  background-color: #FFFFFF;
}

.production_graph_container_title__2szNa {
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(130, 102, 229, 1);
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.production_graph_graph_container__23-3C {
  width: 100%;
  margin: 1rem 0 0 0;
  border-radius: 15px;
  overflow: hidden;
}
.event_logs_container__1JRSz {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  border: 1.5px solid rgba(3, 0, 118, 1);
  border-radius: 15px;
  padding: 1rem 0 0 0;
  background-color: #FFFFFF;
}

.event_logs_container_title__2TrtO {
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(255, 181, 34, 1);
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.event_logs_table_container__tfi4M {
  width: 95%;
  margin: 1rem auto;
}

.event_logs_el_table__wojJp {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border: unset !important;
  table-layout: fixed;
}

.event_logs_table_header_group__LxCNf>th:nth-of-type(2) {
  padding: 0 5px 0 0;
}

.event_logs_table_header_group__LxCNf>th:nth-of-type(3) {
  padding: 0 0 0 5px;
}

.event_logs_parent_header__2NDHK {
  background-color: rgba(3, 0, 107, 0.65);
  color: #FFFFFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 0;
}

.event_logs_table_header__LxAln>th {
  background-color: transparent;
  padding: 0;
}

.event_logs_table_header_cell__2eyg8 {
  position: relative;
  background-color: #03006B26;
  padding: 5px 0px 8px 0px;
  font-weight: 600;
  font-size: 9px;
  color: #030076;
  margin-bottom: 5px;
}

.event_logs_table_header__LxAln>th:nth-of-type(2) .event_logs_table_header_cell__2eyg8,
.event_logs_table_header__LxAln>th:nth-of-type(6) .event_logs_table_header_cell__2eyg8 {
  border-bottom-left-radius: 5px;
}

.event_logs_table_header__LxAln>th:nth-of-type(5) {
  padding-right: 5px;
}

.event_logs_table_header__LxAln>th:nth-of-type(6) {
  padding-left: 5px;
}

.event_logs_table_header__LxAln>th:nth-of-type(5) .event_logs_table_header_cell__2eyg8,
.event_logs_table_header__LxAln>th:nth-of-type(9) .event_logs_table_header_cell__2eyg8 {
  border-bottom-right-radius: 5px;
}

.event_logs_table_header_cell__2eyg8::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  content: '\0025BC';
}

.event_logs_table_body__2QK7V>td {
  padding: 7px 0px;
  border: unset !important;
}

.event_logs_cell__a4st3 {
  margin: 0 5px;
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
}

.event_logs_table_body__2QK7V>td:nth-of-type(2) .event_logs_cell__a4st3 {
  margin-left: 0;
}

.event_logs_table_body__2QK7V>td:nth-of-type(9) .event_logs_cell__a4st3 {
  margin-right: 0;
}

.event_logs_el_table__wojJp th:first-child,
.event_logs_el_table__wojJp td:first-child {
  width: 120px;
}

.event_logs_el_table__wojJp th:not(:first-child),
.event_logs_el_table__wojJp td:not(:first-child) {
  width: auto;
}

.event_logs_el_table_row__2i4FT {
  position: relative;
}

.event_logs_row_title__2HN7x {
  max-width: 100px;
  position: relative;
  text-align: left;
  font-size: 9px;
  font-weight: 600;
  padding: 10px 5px;
  border-radius: 5px;
}

.event_logs_title_border__1Yzt4 {
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 10px;
  width: 60%;
  height: calc(100% + 8px);
}

.event_logs_row_title__2HN7x::after {
  content: '\25B6';
  position: absolute;
  transform: translate(50%, -50%);
  right: 0;
  top: 50%;
}

.event_logs_table_footer__3U7-B>td {
  border: none;
}

.event_logs_deepnote__2EPd0 {
  text-align: justify;
  font-size: 7px;
}

.event_logs_red__1s_Jy {
  background-color: rgba(255, 231, 224, 1);
}

.event_logs_blue__16yYc {
  background-color: rgba(211, 228, 255, 1);
}

.event_logs_green__o7zKS {
  background-color: rgba(241, 247, 189, 1);
}

.event_logs_title_border__1Yzt4.event_logs_red__1s_Jy {
  background-color: #D95D39;
}

.event_logs_title_border__1Yzt4.event_logs_blue__16yYc {
  background-color: #2E5EAA;
}

.event_logs_title_border__1Yzt4.event_logs_green__o7zKS {
  background-color: #B5C334;
}

.event_logs_red__1s_Jy::after {
  color: #D95D39;
}

.event_logs_blue__16yYc::after {
  color: #2E5EAA;
}

.event_logs_green__o7zKS::after {
  color: #B5C334;
}
* {
  font-family: 'Montserrat', sans-serif;
}

table {

  border-bottom: 0;
  border-collapse: collapse;
  border-left: 1px black solid;
}

table > thead > tr > td {
  background-color: green;
  color: white;
  font-size: 14pt;
  text-align: center;
  padding: 10px;
  border: 1px black solid;
}

table > tbody > tr > td {
  padding: 5px;
  border-bottom: 1px black solid;
  border-right: 1px black solid;
  text-align: center;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #CE0814;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

