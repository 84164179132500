.footer_content {
  font-size: 10pt;
  color: #999;
  display: flex;
  flex-direction: row;
  padding: 30px 20px 10px 20px;
  align-items: center;
}

.fixed_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.page_count {
  padding-right: 5%;
  flex: 1;
  text-align: right;
}

.page_count:before {
  counter-increment: page_counter;
  content: "#" counter(page_counter);
}