.header {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.small {
  font-size: 15pt !important;
}

.header_title {
  flex: 1;
  min-height: 30px;
  padding: 0 0 0 5px;
  color: #002d53;
  font-weight: bold;
  font-size: 17pt;
}

.header_title:not(.not_heading):before {
  content: counter(headings) ".";
}