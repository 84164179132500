.header {
  padding: 7px 20px 7px 0;
  border-bottom: 1.2px solid #e00000 !important;
}

.header_content {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 10px 0px;
}

.header_title {
  flex: 1;
  height: 30;
  padding: 4px 0px 4px 10px;
  color: #002d53;
  font-weight: bold;
  font-size: 17pt;
  margin-top: 0px;
  margin-left: 15px;
}

.header_title:not(.not_heading):before {
  content: counter(headings) ". ";
}

.company_logo {
  align-self: flex-end;
  max-height: 120px;
  max-width: 200px;
  height: 30;
  padding: 4px 0px 4px 10px;
  margin-left: 15px;
}