.information_box_title {
  background-color: #e00000;
  color: white;
  font-size: 12px;
  font-weight: 800;
  padding: 10px;
  border: 1px solid #000000;
}

.information_box_content {
  padding: 10px;
  border: 1px solid #000000;
  border-top: none;
  line-height: 1.7;
}