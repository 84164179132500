.componentContainer {
  margin: 15 0;
}

.headerTitle {
  padding: 4px 0px 4px 10px;
  color: #002d53;
  font-weight: bold;
  font-size: 15pt;
  margin-top: 0;
  margin-left: 8;
}

.headerSubTitle {
  padding: 4px 0px 4px 10px;
  color: #002d53;
  font-weight: bold;
  font-size: '13pt';
  margin-top: 12;
  margin-bottom: 8;
  margin-left: 20;
}