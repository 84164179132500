.sheet {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

body[class="A4 portrait"] .sheet {
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #fffaf8;
}