.container {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  border: 1.5px solid rgba(3, 0, 118, 1);
  border-radius: 15px;
  padding: 1rem 0 0 0;
  background-color: #FFFFFF;
}

.container_title {
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(255, 137, 70, 1);
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.table_container {
  width: 95%;
  margin: 1rem auto;
}

.pd_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border: unset !important;
}

.table_header>th {
  background-color: transparent;
  padding: 0;
}

.table_header_cell {
  position: relative;
  background-color: #03006B26;
  padding: 5px 0px 8px 0px;
  font-weight: 600;
  font-size: 9px;
  color: #030076;
  margin-bottom: 5px;
  min-width: 80px;
}

.table_header>th:nth-of-type(2) .table_header_cell {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_header>th:nth-of-type(5) .table_header_cell {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table_header_cell::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  content: '\0025BC';
}

.table_body>td {
  padding: 7px 0px;
  border: unset !important;
}

.table_body>td:nth-of-type(1) {
  width: 120px;
}

.cell {
  margin: 0 5px;
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
}

.table_body>td:nth-of-type(2) .cell {
  margin-left: 0;
}

.table_body>td:nth-of-type(5) .cell {
  margin-right: 0;
}

.pd_table_row {
  position: relative;
}

.row_title {
  max-width: 100px;
  position: relative;
  text-align: left;
  font-size: 9px;
  font-weight: 600;
  padding: 10px 5px;
  border-radius: 5px;
}

.title_border {
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 10px;
  width: 60%;
  height: calc(100% + 8px);
}

.row_title::after {
  content: '\25B6';
  position: absolute;
  transform: translate(50%, -50%);
  right: 0;
  top: 50%;
}

.green {
  background-color: rgba(220, 236, 216, 1);
}

.blue {
  background-color: rgba(204, 233, 255, 1);
}

.orange {
  background-color: #FCE7D6;
}

.purple {
  background-color: rgba(243, 227, 255, 1);
}

.title_border.green {
  background-color: #519E3E;
}

.title_border.blue {
  background-color: #3B8DC8;
}

.title_border.orange {
  background-color: #EF8534;
}

.title_border.purple {
  background-color: #8E3BC8;
}

.green::after {
  color: #519E3E;
}

.blue::after {
  color: #3B8DC8;
}

.orange::after {
  color: #EF8534;
}

.purple::after {
  color: #8E3BC8;
}