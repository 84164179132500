.table {
  border-bottom: 0;
  border-collapse: collapse;
  border-left: 1px black solid;
  margin-top: 30px;
  table-layout: fixed;
}

.table>thead>tr>td {
  background-color: #e00000;
  color: white;
  font-size: 9.5pt;
  text-align: center;
  padding: 10px;
  border: 1px black solid;
}

.table>tbody>tr>td {
  padding: 5px;
  border-bottom: 1px black solid;
  border-right: 1px black solid;
  text-align: center;
  font-size: 7pt;
}

.red_table>thead>tr>td {
  background-color: #e00000;
  color: white;
  font-size: 9.5pt;
  text-align: center;
  padding: 10px 0px;
  border: 1px black solid;
  word-break: keep-all;
}

.small_text>thead>tr>td {
  font-size: 7pt;
  height: 40px;
}

.budgeted_table {
  margin-top: 10px;
  table-layout: fixed;
}

.budgeted_table>thead>tr>td {
  padding: 2px;
}

.budgeted_table>tbody>tr>td {
  font-size: 7pt;
}

.ticket_report_table {
  border-left: 0;
  margin-top: 30px;
  font-size: 11px;
  padding: 0px;
  table-layout: fixed;
}

.ticket_report_table tbody tr {
  border: 1px black solid;
}

.ticket_table_key {
  background-color: #bdbdbd;
  color: #333333;
  font-weight: bold;
}

.ticket_table_long_text {
  text-align: justify;
  word-wrap: break-word;
  font-size: 10px;
  padding: 10px;
  vertical-align: unset;
}

.ticket_table_value {
  background-color: #f9f9f9;
}

.zebra_row:nth-child(even) {
  background-color: #e0e0e0;
}

.multiRow {
  padding: 0 !important;
}

.multiRowItem:last-child {
  border-bottom: 0;
}

.multiRowItem {
  padding: 5;
  border-bottom: 1px solid black;
}