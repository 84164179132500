.header_row_container {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.station_title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(3, 0, 118, 1);
}

.report_date {
  font-size: 12px;
  font-weight: 500;
  color: rgba(3, 0, 118, 1);
}

.header_logo {
  align-self: flex-end;
  max-height: 120px;
  max-width: 200px;
  height: 30;
}

.horizontal_divider {
  margin: 1rem 0;
  border-bottom: 1.5px solid rgba(255, 93, 0, 0.5) !important;
}