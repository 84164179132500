.sheet {
  display: flex;
  flex-direction: column;
}

.sheet:not(.cover):not(.h) {
  counter-increment: headings;
}

body[class="A4 portrait"] .sheet {
  margin-left: auto;
  margin-right: auto;
}

body[class="A4 landscape"] .sheet {
  margin-left: auto;
  margin-right: auto;
}