body[class="A4 portrait"] .sheet {
  margin-left: auto;
  margin-right: auto;
}

body[class="A4 landscape"] .sheet {
  margin-left: auto;
  margin-right: auto;
}

.vertical {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.vertical .header {
  padding: 1cm;
}

.vertical .header-content {
  display: flex;
  flex-direction: row;
}

.vertical .header-content > .title {
  flex: 1;
  background-color: #ce0814;
  height: 20;
  padding:4px 0px 4px 10px;
  color: white;
  font-weight: bold;
  font-size: 14pt;
}

.vertical .header-content > .logo {
  align-self: flex-end;
  max-height: 120px;
  max-width: 200px;
  height: 28px;
}

.vertical .content {
  flex-grow: 1;
  background-color: white;
  padding: 0 1cm;
  overflow: hidden;
}

.vertical .footer {
  padding: 1cm;
}

.vertical .footer-content {
  font-size: 10pt;
  color: #999;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical .footer-content > .title {
  text-align: center;
}