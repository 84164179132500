.title {
  background-color: #e00000;
  color: white;
  font-size: 12px;
  font-weight: 800;
  padding: 10px;
  border: 1px solid #000000;
}

.gallery {
  max-height: 840px;
  overflow: hidden;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  border: 1px solid #000000;
  border-top: none;
}

.gallery_item {
  margin: 0;
}

.gallery_img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}