.index_list {
  list-style-type: none;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #002d53;
}

.list_item:before {
  margin-right: 0.25em;
}