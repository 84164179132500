.container {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  border: 1.5px solid rgba(3, 0, 118, 1);
  border-radius: 15px;
  padding: 1rem 0 0 0;
  background-color: #FFFFFF;
}

.container_title {
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(130, 102, 229, 1);
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.graph_container {
  width: 100%;
  margin: 1rem 0 0 0;
  border-radius: 15px;
  overflow: hidden;
}