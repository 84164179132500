
.ticket-report-table{
  border-left:0;
  font-size: 11px;
  padding: 0px;
  table-layout: fixed;
}

.ticket-report-table tbody tr { border: 1px black solid; }

.ticket-report-table tbody tr .key{
  background-color: #bdbdbd;
  color: #333333;
  font-weight: bold;
}

.ticket-report-table tbody tr .long-text{
  text-align: justify;
  word-wrap: break-word;
  font-size: 10px;
  padding: 10px;
  vertical-align: unset;
}

.ticket-report-table tbody tr .value{ background-color: #f9f9f9; }
