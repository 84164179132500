.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding: 20px;
}

.contentFixed {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding: 20px;
  overflow: hidden;
}